import axios from "axios";
import qs from "qs";

axios.defaults.baseURL = 'http://zhuxue.net/api/v1/'  //正式
// axios.defaults.baseURL = 'http://192.168.31.54/api/v1/'  //测试

export default function axiosApi (url, data, method) {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: qs.stringify(data)
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      });
  })
}