import { createRouter, createWebHistory } from 'vue-router'
//import Router from 'vue-router'
// Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    meta: {
      title: "主页",
      index: '1',
      keepAlive: true
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: "关于我们",
      index: '2',
      keepAlive: true
    },
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue'),
    meta: {
      title: "新闻中心",
      index: '3',
      keepAlive: true
    },
  },
  {
    path: '/youshi',
    name: 'Youshi',
    component: () => import('../views/Youshi.vue'),
    meta: {
      title: "我们的优势",
      index: '4',
      keepAlive: true
    },
  },
  {
    path: '/yuyan',
    name: 'Yuyan',
    component: () => import('../views/Yuyan.vue'),
    meta: {
      title: "中文语言培训优势",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/kaoshi',
    name: 'Kaoshi',
    component: () => import('../views/Kaoshi.vue'),
    meta: {
      title: "考试准备中心",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue'),
    meta: {
      title: "常见问题",
      index: '6',
      keepAlive: true
    },
  },
  {
    path: '/HSK',
    name: 'HSK',
    component: () => import('../views/HSK.vue'),
    meta: {
      title: "HSK",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/BCT',
    name: 'BCT',
    component: () => import('../views/BCT.vue'),
    meta: {
      title: "BCT",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/MCT',
    name: 'MCT',
    component: () => import('../views/MCT.vue'),
    meta: {
      title: "MCT",
      index: '5',
      keepAlive: true
    },
  },

  {
    path: '/select-school',
    name: 'Select-school',
    component: () => import('../views/Search1.vue'),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
  },


  {
    path: '/recommend',
    name: 'Recommend',
    component: () => import('../views/Recommended.vue'),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/popular-schools',
    name: 'Popular-schools',
    component: () => import('../views/PopularSchools.vue'),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/popular-major',
    name: 'Popular-major',
    component: () => import('../views/PopularMajor.vue'),
    props: route => ({ search: route.query.search }),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/school-detail/:id',
    name: 'School-detail',
    component: () => import('../views/SchoolDetail.vue'),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/news-detail/:id',
    name: 'Newsdetail',
    component: () => import('../views/NewsDetail.vue'),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/lvyou',
    name: 'Lvyou',
    component: () => import('../views/Lvyou.vue'),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/serve',
    name: 'serve',
    component: () => import('../views/serve.vue'),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
    children: [{
      path: '/baoxian',
      name: 'Baoxian',
      component: () => import('../views/Baoxian.vue'),
      meta: {
        title: "hsk报名表",
        index: '5',
        keepAlive: true
      },
    },
    {
      path: '/jiaocai',
      name: 'Jiaocai',
      component: () => import('../views/Jiaocai.vue'),
      meta: {
        title: "hsk报名表",
        index: '5',
        keepAlive: true
      },
    },
    {
      path: '/zhusu',
      name: 'Zhusu',
      component: () => import('../views/Zhusu.vue'),
      meta: {
        title: "hsk报名表",
        index: '5',
        keepAlive: true
      },
    },
    {
      path: '/jieji',
      name: 'Jieji',
      component: () => import('../views/Jieji.vue'),
      meta: {
        title: "hsk报名表",
        index: '5',
        keepAlive: true
      },
    },
    {
      path: '/xuexiao',
      name: 'Xuexiao',
      component: () => import('../views/Xuexiao.vue'),
      meta: {
        title: "hsk报名表",
        index: '5',
        keepAlive: true
      },
    },
    {
      path: '/SIM-Card',
      name: 'SIM-Card',
      component: () => import('../views/SIMCard.vue'),
      meta: {
        title: "hsk报名表",
        index: '5',
        keepAlive: true
      },
    },
    ]
  },
  {
    path: '/live-in-china',
    name: 'Live-in-china',
    component: () => import('../views/Shenghuo.vue'),
    meta: {
      title: "中国生活",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/live-in-china-detail/:id',
    name: 'live-in-china-detail',
    component: () => import('../views/ShenghuoDetail.vue'),
    meta: {
      title: "hsk报名表",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/job-wanted',
    name: 'Job-wanted',
    component: () => import('../views/Qiuzhi.vue'),
    meta: {
      title: "求职",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/visa',
    name: 'Visa',
    component: () => import('../views/Qianzheng.vue'),
    meta: {
      title: "签证中心",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/thesis-proofreading-service',
    name: 'thesis-proofreading-service',
    component: () => import('../views/Jiaodui.vue'),
    meta: {
      title: "校对服务",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/scholarship',
    name: 'Scholarship',
    component: () => import('../views/Jiangxuejin.vue'),
    meta: {
      title: "奖学金",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/scholarship-detail-1',
    name: 'Scholarship-detail-1',
    component: () => import('../views/JiangxuejinDetail.vue'),
    meta: {
      title: "奖学金",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/Fankui.vue'),
    meta: {
      title: "奖学金",
      index: '5',
      keepAlive: true
    },
  },
  {
    path: '/visa-center-list',
    name: 'visa-center-list',
    component: () => import('../views/visaCenterList.vue'),
    meta: {
      title: "签证中心",
      index: '5',
      keepAlive: true
    },
    children: [{
      path: '/visa-center-detail/:id',
      name: 'visa-center-detail',
      component: () => import('../views/visaCenter.vue'),
      meta: {
        title: "签证中心",
        index: '5',
        keepAlive: true
      },
    },
    ]
  },
]


const router = createRouter({
  history: createWebHistory('/home/'),
  routes,
})
export default router
