<template>
  <div id="home">

    <Head></Head>
    <div class="_page"
         :style="'min-height:'+wh+'px;'">
      <router-view v-slot="{ Component }">
        <component class="view"
                   :is="Component" />
      </router-view>
    </div>
    <Foot></Foot>
    <el-backtop :bottom="100">
      <div style="
        height: 100%;
        width: 100%;
        background-color: #0c3879;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
        text-align: center;
        line-height: 30px;
        padding:5px 0;
        color: #fff;
      ">
        <p style="line-height: 20px;height:20px;">
          <el-icon>
            <ArrowUpBold />
          </el-icon>
        </p>
        TOP
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Head from '@/components/head.vue'
import Foot from '@/components/Foot.vue'
import '@/assets/fonts/index/iconfont.css'
import { ArrowUpBold } from '@element-plus/icons'

export default {
  name: 'Index',
  components: {
    Head,
    Foot,
    ArrowUpBold
  },
  data () {
    return {
      wh: 300
    }
  },
  mounted () {
    this.wh = window.screen.height - 120 - 348
  },

}
</script>

<style lang="scss">
#app {
  font-family: "Arial", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 16px;
  line-height: 28px;
}
html,
body,
div,
span,
p,
a,
img,
ul,
li,
form,
table,
tbody,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
body,
input,
textarea,
select,
button {
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: ltr;
  text-align: left;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}
body,
html {
  font-family: "微软雅黑", "Microsoft YaHei", "playfair_regular", Arial,
    "PingFangSC-Regular", "Helvetica", sans-serif;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  color: #000;
  -webkit-text-size-adjust: 100%;
  line-height: 1.4286;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:active {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
input,
select {
  outline: none;
  -webkit-appearance: none;
  border: 0;
}
button {
  background: none;
  border: 0;
  outline: none;
  font-family: Arial, "PingFang", "Helvetica", sans-serif;
}
h3 {
  margin: 0;
}
.pd40 {
  padding: 40px 0;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.imgbox {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  img {
    max-width: 100%;
  }
}
._page {
  min-height: 100%;
}
.headTit {
  width: max-content;
  margin: 0 auto;
  margin-bottom: 40px;
  text-align: center;
  h3 {
    font-size: 40px;
    font-weight: bold;
    span {
      color: #0c3879;
    }
  }
  p {
    font-size: 20px;
    color: #666;
  }
}
._globalBtn {
  display: block;
  margin: auto;
  margin-top: 40px;
  background-color: #0aaaf1;
  width: max-content;
  padding: 10px 40px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  &:hover {
    background-color: #0c3879;
  }
  &.yel {
    background-color: #ffc107;
    color: #333;
  }
  &.theme {
    background-color: #0c3879;
  }
}
.el-backtop {
  width: 60px;
  height: 60px;
}
</style>
