<template>
  <div class="Foot">
    <div class="cont">
      <div class="container">
        <el-row class="list"
                :gutter="24">
          <el-col :span="6">
            <div class="ftlogo imgbox">
              <img src="../assets/logo2.png"
                   alt="">
            </div>
          </el-col>
          <el-col :span="18">
            <div class="contact">
              <p><a :href="item.link"
                   v-for="(item,index) in footNav"
                   :key="index">{{item.name}}</a></p>
              <p>
                <span>Email: zhuxueedu@gmail.com</span>
                <span>Tel: 86-10-62040776</span>
                <span>Website: <a href="www.zhuxue.net">www.zhuxue.net</a></span>
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="botBar">
      <div class="container">
        <div class="text">
          <span>Copyrignt © 2021 ZHUXUE All rights reserved </span> <span>公安备案号: <a href="">11010802022681</a></span> <span>京ICP备: <a href="">19028568号-3 </a></span> <span></span>
          <!-- <span>Design By : <a href="http://www.liannet.net">奈特网络</a></span> -->
          <span><a href="https://uweb.umeng.com/v1/login.php?siteid=1280511176">站长统计</a></span>
          <div id="tjdm"></div>
        </div>
        <div class="navs">
          <a href="">
            <i class="iconfont">&#xe60b;</i>
          </a>
          <a href="">
            <i class="iconfont">&#xe60f;</i>

          </a>
          <a href="">
            <i class="iconfont">&#xe60d;</i>

          </a>
        </div>
      </div>
    </div>
    <div class="fixedBot">
      <div class="container">
        <el-row>
          <el-col :span="11">
            <div class="leftBlo">
              <el-row>
                <el-col :span="8">
                  <h3>WE ARE HERE
                    TO HELP:</h3>
                </el-col>
                <el-col :span="12">
                  <div class="_row">
                    <div class="aut">
                      <div class="imgbox">
                        <img src="@/assets/01.png"
                             alt="">
                      </div>
                      <p>Catherine</p>
                      <div class="cards">
                        <div class="_l">
                          <div class="imgbox">
                            <img src="@/assets/01.png"
                                 alt="">
                          </div>
                          <p>Catherine</p>
                        </div>
                        <div class="_c">
                          <h4>WeChat:</h4>
                          <p>huya0126</p>
                          <h4>WhatsApp/Phone:</h4>
                          <p>+86-10-62040776</p>
                        </div>
                        <div class="_r">
                          <div class="imgbox">
                            <img src="@/assets/kefu001.jpg"
                                 alt="">
                          </div>
                          <p>WeChat</p>
                        </div>
                      </div>
                    </div>
                    <div class="aut">
                      <div class="imgbox">
                        <img src="@/assets/03.png"
                             alt="">
                      </div>
                      <p>Amira</p>
                      <div class="cards">
                        <div class="_l">
                          <div class="imgbox">
                            <img src="@/assets/03.png"
                                 alt="">
                          </div>
                          <p>Amira</p>
                        </div>
                        <div class="_c">
                          <h4>WeChat:</h4>
                          <p>amiratxz</p>
                          <h4>WhatsApp/Phone:</h4>
                          <p>+86-10-62040776</p>
                        </div>
                        <div class="_r">
                          <div class="imgbox">
                            <img src="@/assets/kefu003.jpg"
                                 alt="">
                          </div>
                          <p>WeChat</p>
                        </div>
                      </div>
                    </div>
                    <div class="aut">
                      <div class="imgbox">
                        <img src="@/assets/02.png"
                             alt="">
                      </div>
                      <p>Audrey</p>
                      <div class="cards">
                        <div class="_l">
                          <div class="imgbox">
                            <img src="@/assets/02.png"
                                 alt="">
                          </div>
                          <p>Audrey</p>
                        </div>
                        <div class="_c">
                          <h4>WeChat:</h4>
                          <p>audrey_tang</p>
                          <h4>WhatsApp/Phone:</h4>
                          <p>+86-10-62040776</p>
                        </div>
                        <div class="_r">
                          <div class="imgbox">
                            <img src="@/assets/kefu002.jpg"
                                 alt="">
                          </div>
                          <p>WeChat</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="13">
            <div class="rightBlo">
              <div class="inputItem">
                <el-input v-model="form.cert_name"
                          placeholder="name" />
              </div>
              <div class="inputItem">
                <el-input v-model="form.phone"
                          placeholder="phone" />
              </div>
              <div class="inputItem">
                <el-input v-model="form.email"
                          placeholder="email" />
              </div>
              <button class="_globalBtn"
                      style="margin:0;"
                      @click="onSubmit()">submit</button>
            </div>
            <div class="_tips">If you need more help, please leave your personal information. We will contact you ASAP.</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import axiosApi from "../api/api.js";
import { ElMessage } from 'element-plus'
export default {
  name: 'Foot',
  props: [],
  components: {
  },
  data () {
    return {
      form: {
        cert_name: '',
        phone: '',
        email: '',
      },
      footNav: [{
        name: "About Us",
        link: '/home/about',

      },
      {
        name: "Colleges and Universities",
        link: '/home/popular-schools',
      },
      {
        name: "Education Centre",
        link: '/home/yuyan',

      },
      {
        name: "Products and Services",
        link: '/home/xuexiao',

      },
      {
        name: "Resources",
        link: '/home/scholarship',

      }]
    }
  },
  methods: {
    onSubmit () {
      if (this.form.cert_name == '') return ElMessage.error('Please Input You Name')
      if (this.form.phone == '') return ElMessage.error('Please Input You Phone Number')
      if (this.form.email == '') return ElMessage.error('Please Input You Email')
      axiosApi('message', {
        form_type: 6,
        ...this.form
      }, 'post').then(res => {
        if (res.data.status) {
          ElMessage.success('success')
          this.ShowForm = false
          this.form.cert_name = ''
          this.form.phone = ''
          this.form.email = ''
        } else {
          ElMessage.error('error')
        }
      })
    }
  },
  mounted () {
    this.wh = window.screen.height - 120 - 348
    const script = document.createElement('script')
    script.language = 'JavaScript'
    script.src = 'https://s4.cnzz.com/z_stat.php?id=1280511176&web_id=1280511176'
    script.id = 'cnzz'
    document.getElementById("tjdm").appendChild(script)
  },
  watch: {
    '$route': {
      handler () {
        setTimeout(() => { //避免首次获取不到window._czc
          if (window._czc) {
            let location = window.location;
            let contentUrl = location.pathname + location.hash;
            let refererUrl = '/';
            // 用于发送某个URL的PV统计请求，
            window._czc.push(['_trackPageview', contentUrl, refererUrl])
            window._czc.push(["_setAutoPageview", false]);
          }
        }, 300)
      },
      immediate: true  // 首次进入页面即执行
    }
  }
}
</script>

<style lang="scss" scoped>
.cont {
  padding: 20px 0;
  background-color: #414756;
  .ftlogo {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    opacity: 0.8;
  }
  .contact {
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    p {
      font-size: 14px;
      padding: 2px 0;
      a {
        display: inline-block;
        padding: 0 10px;
        height: 14px;
        line-height: 1;
        border-right: 1px solid #dedede;
        opacity: 0.8;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: none;
        }
        &:hover {
          opacity: 1;
        }
      }
      span {
        opacity: 0.8;
        margin-right: 10px;
      }
    }
  }
}
.botBar {
  background-color: #004168;
  color: #fff;
  margin-bottom: 75px;
  border-bottom: 1px solid #999;
  .text {
    display: flex;
    font-size: 14px;
    span {
      margin-right: 20px;
      a {
        text-decoration: underline;
      }
    }
  }
  .container {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
  }
  .navs {
    a {
      margin-left: 10px;
      i {
        font-size: 24px;
      }
    }
  }
}
.fixedBot {
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #004168;
  .leftBlo {
    h3 {
      color: #ffb937;
      display: flex;
      align-items: center;
      height: 100%;
    }
    ._row {
      display: flex;
      .aut {
        padding: 0 20px;
        border-right: 1px dashed #eaeaea;
        & > .imgbox {
          width: 38px;
          border-radius: 50%;
          border: 2px solid #ffb937;
          margin: 0 auto;
        }
        p {
          color: #fff;
          font-size: 12px;
          text-align: center;
          margin-top: 5px;
          line-height: 1;
        }
        .cards {
          display: none;
          position: absolute;
          bottom: 80px;
          background-color: #fff;
          box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.2);
          padding: 15px;
          border-radius: 8px;
          align-items: center;
          color: #333;
          ._l {
            .imgbox {
              width: 50px;
              border-radius: 50%;
            }
            p {
              color: #ffb937;
            }
          }
          ._c {
            margin: 0 10px;
            h4 {
              margin: 0;
              margin-bottom: 5px;
              font-size: 14px;
              line-height: 1;
            }
            p {
              color: #333;
              text-align: left;
              margin-bottom: 10px;
            }
          }
          ._r {
            width: 75px;
            p {
              color: #333;
            }
          }
        }
        &:hover .cards {
          display: flex;
        }
      }
    }
  }
  .rightBlo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    height: 30px;
    ._globalBtn {
      height: 30px;
      line-height: 30px;
      padding: 0 30px;
      cursor: pointer;
      &:hover {
        background-color: #ffb937;
      }
    }

    .line {
      height: 100%;
      width: 1px;
      border-right: 1px dashed #dedede;
      margin: 0 10px;
    }
    a {
      height: max-content;
      line-height: 1;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      border-radius: 15px;
      &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.3);
      }
      i {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
  ._tips {
    color: #ffb937;
    font-size: 12px;
    margin-top: 5px;
  }
  .container {
    padding: 7px 0;
  }
}
.inputItem ::v-deep {
  .el-input {
    width: 180px;
    height: 30px;
    line-height: 30px;
  }
  .el-input__inner {
    line-height: 30px;
    height: 30px;
  }
}
</style>