<template>
  <div class="header">
    <div class="topBar">
      <div class="container">
        <div class="right">
          <router-link to="/about">About Us</router-link>
          <router-link to="/faq">FAQ</router-link>
          <el-dropdown class="selectLang"
                       popper-class="selectLangMenu">
            <div class="el-dropdown-link">
              <div class="imgbox">
              </div> English<i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <a href="http://cn.zhuxue.net/home/">简体中文</a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="http://zhuxue.net/home/">English</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="container">
        <router-link to="/"
                     class="logoimg">
          <img src="../assets/logo.png"
               alt="">
        </router-link>
        <div class="bar"
             @mouseover="onMouseEnter"
             @mouseleave="onMouseOut">
          <div v-for="(item,index) in navs"
               :key="index"
               @click="showActive(index)"
               class="item">

            <router-link class="indexItem"
                         :to="item.link">{{ item.name }}</router-link>
          </div>
          <div class="erItemCont">
            <el-collapse-transition>
              <div class="container"
                   v-if="erItemFlag">
                <div v-for="(item,index) in navs"
                     :key="index">
                  <div v-for="(item2,index2) in item.erji"
                       :key="index2">
                    <a :href="'/home'+item2.link"
                       v-if="item2.link == '/select-school'">{{item2.name}}</a>
                    <router-link v-else
                                 :to="item2.link">{{item2.name}}</router-link>
                  </div>

                </div>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Head',
  props: {
  },
  data () {
    return {
      navs: [
        {
          name: 'Home',
          link: '/',
        },
        {
          name: "Universities",
          link: '/',
          erji: [{
            name: 'Choose From Cities',
            link: '/select-school'
          },
          {
            name: 'Popular Universities',
            link: '/popular-schools'
          }, {
            name: 'Outstanding Progarms',
            link: '/popular-major'
          }, {
            name: 'ZHUXUE Highlight',
            link: '/recommend'
          }]
        },
        {
          name: "Education Center",
          link: '/',
          erji: [{
            name: 'Language Center',
            link: '/yuyan'
          },
          {
            name: 'Test Preparation Center',
            link: '/kaoshi'
          }, {
            name: 'Proofreading Service',
            link: '/thesis-proofreading-service'
          }]
        },
        {
          name: "Services",
          link: '/',
          erji: [{
            name: 'Insurance',
            link: '/baoxian'
          },
          {
            name: 'Travel Center',
            link: '/lvyou'
          }, {
            name: 'Official Textbooks',
            link: '/jiaocai'
          }, {
            name: 'SIM Card',
            link: '/SIM-Card'
          }, {
            name: 'Accommodation',
            link: '/zhusu'
          },
          {
            name: 'Airport pickup',
            link: '/jieji'
          }, {
            name: 'Application Service',
            link: '/xuexiao'
          }]
        },
        {
          name: "Resources",
          link: '/',
          erji: [{
            name: 'Visa Application',
            link: '/visa-center-detail/index'
          },
          {
            name: 'The Scholarship',
            link: '/scholarship'
          }, {
            name: 'Job Center',
            link: '/job-wanted'
          }, {
            name: 'Feedback',
            link: '/feedback'
          }, {
            name: 'Life Center',
            link: '/live-in-china'
          }]
        },
        {
          name: "About us",
          link: '/',
          erji: [{
            name: 'About ZHUXUE',
            link: '/about'
          },
          {
            name: 'Partners',
            link: '/'
          }, {
            name: 'Our Advantages',
            link: '/youshi'
          }, {
            name: 'News Center',
            link: '/news'
          }, {
            name: 'FAQ',
            link: '/faq'
          }]
        }
      ],
      erItemFlag: false,
      show: false,
      activeIndex: '1',
      hoverIndex: null
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    },
    onMouseEnter () {
      this.erItemFlag = true
    },
    onMouseOut () {
      this.hoverIndex = undefined
      this.erItemFlag = false
    },
    showActive (i) {
      let indexItem = document.querySelectorAll('.indexItem')
      indexItem.forEach((item) => {
        item.classList.remove('on')
      })
      indexItem[i].classList.add('on')
    }
  }
}
</script>
<style lang="scss">
.selectLangMenu {
  .el-dropdown-menu__item {
    display: flex !important;
    .imgbox {
      margin-right: 10px;
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
.header {
  .nav {
    position: relative;
    .erItemCont {
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      z-index: 999;
      background-color: rgba($color: #000000, $alpha: 0.35);
      .container {
        justify-content: flex-end;
        align-items: flex-start;
        & > div {
          padding: 10px 0;
        }
      }
      a {
        display: block;
        width: 150px;
        text-align: center;
        padding: 10px 0;
        font-size: 14px;
        color: #fff;
        &:hover {
          color: #ffc107;
        }
      }
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logoimg {
        height: 80px;
        display: flex;
        align-items: center;
        width: max-content;
        img {
          width: max-content;
          height: max-content;
        }
      }
      .bar {
        display: flex;
        align-items: center;
        .item {
          width: 150px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          text-align: center;
          font-size: 14px;
          & > a {
            color: #004168;
            font-weight: bold;
          }
          & > a.on,
          &:hover > a {
            color: #333;
          }
        }
      }
    }
  }
  .topBar {
    background-color: #004168;
    .right {
      width: max-content;
      height: 40px;
      margin-right: 0;
      margin-left: auto;
      display: flex;
      a {
        opacity: 0.7;
        line-height: 40px;
        color: #fff;
        margin: 0 10px;
        font-size: 14px;
        &:hover {
          opacity: 1;
        }
      }
      .selectLang {
        .el-dropdown-link {
          display: block;
          color: #fff;
          background-color: #333;
          line-height: 40px;
          padding: 0 10px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
