import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

// require('animate.css/animate.min.css')
import { WOW } from 'wowjs'


const app = createApp(App)
app.config.globalProperties.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 150, // default
  mobile: true, // default
  live: true, // default
  callback: function (box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
})

app.use(ElementPlus, {
  locale: zhCn,
})

app.use(router)
app.use(store)
app.use(Vue3VideoPlayer, { lang: 'zh-CN' })
app.mount('#app')

